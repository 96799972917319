/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { rem } from "@src/theme";

interface ComponentProps {
  size?: string;
  link: string;
  label: string;
}
const PlainCard = ({ size = "sm", link, label }: ComponentProps) => (
  <li
    sx={{
      display: "inline-block",
      // minHeight: "44px",
      color: "black",
      "> *": {
        color: "black",
      },
      //   display: "inline-block",
      mr: "8px",
      mb: "8px",

      fontWeight: 600,
      a: {
        height: "100%",
        width: "100%",
      },
    }}
  >
    <Link
      className="link-hover bg-hover"
      to={link}
      sx={{
        padding:
          size === "md"
            ? "44px 26px"
            : size === "xs"
            ? "10px 12px"
            : "14px 16px",
        bg: "lightGrey",
        display: "block",
        fontSize:
          size === "xs" ? [rem(12), null, rem(13)] : [rem(12), null, rem(16)],
      }}
    >
      {" "}
      {label}
    </Link>
  </li>
);
export default PlainCard;
